import dynamic from "next/dynamic";

import { useGetAdConfig, useGetDarkMode } from "_common/hooks/global";

const Advertisement = dynamic ( () => import ( "_components/features/ads/feedMobile" ) );
const AdSlot        = dynamic ( () => import ( "_components/features/ads/slot" ) );

const component = ( { idx } ) => {
	const { HOME_FEED_MWEB: config } = useGetAdConfig ();
	const darkMode                   = useGetDarkMode ();

	if ( !config?.enabled )
		return null;

	if ( ( idx % config?.frequency ) !== config?.offset )
		return null;

	if ( config?.adManager === "vmax" )
		return (
			<Advertisement id = { config?.adUnitId } />
		);

	const path = darkMode ? config?.slotId?.dark : config?.slotId?.light;

	return (
		<AdSlot
			id    = { "gpt-passback" }
			index = { idx }
			path  = { path }
			size  = { config?.size }
		/>
	);
};

export default component;
