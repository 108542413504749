import { Tabs as MantineTabs } from "@mantine/core";

import MobileTab from "_components/atoms/mobile/tab";

import useStyles from "./styles";

const RooterTabsMobile = ( {
	tabs,
	defaultTab,
	colorTheme,
	onClickHandler = () => { },
	background
} ) => {
	const { classes } = useStyles ( { background, colorTheme } );

	return (
		<MantineTabs
			unstyled
			classNames   = { { root: classes.tabRoot, tab: classes.tab } }
			defaultValue = { defaultTab }
			onChange     = { onClickHandler }
		>
			<MantineTabs.List
				className = { classes.tabList }
			>
				{
					tabs.map ( tab => {
						return (
							<MobileTab
								key   = { tab?.value }
								label = { tab?.label }
								value = { tab?.value }
							/>
						);
					} )
				}
			</MantineTabs.List>

		</MantineTabs>
	);
};

export default RooterTabsMobile;
