import { createStyles } from "@mantine/emotion";

import { important }        from "_common/utils";
import { rooterMediaQuery } from "_styles/theme/mediaQuery";

export default createStyles ( ( theme, props ) => {

	return {
		tabRoot: {
			// backgroundColor: theme.other.colors.background[props?.background]
		},
		tab: {
			outline         : theme.other.darkMode ? `1px solid ${ theme.other.paletteNew.darkModeWhite20 } ` : `1px solid ${ theme.other.paletteNew.secondaryLightGrey }`,
			border          : "none",
			padding         : "0.7rem 1.75rem",
			borderRadius    : "1rem",
			overflow        : "hidden",
			cursor          : "pointer",
			marginRight     : "1.2rem",
			backgroundColor : theme.other.darkMode ? theme.other.paletteNew.darkModeDarkGrey20 : theme.other.paletteNew.secondaryWhite,
			color           : theme.other.darkMode ? theme.other.paletteNew.darkModeWhite80 : theme.other.paletteNew.secondaryBlack,

			"&[data-active]": {
				color      : theme.other.darkMode ? `${ theme.other.paletteNew.secondaryWhite } !important` : ( props?.colorTheme === "wallet" ? theme.other.paletteNew.darkSeaGreen : `${ theme.other.paletteNew.primaryDarkBlue } !important ` ),
				background : theme.other.darkMode ? ( props?.colorTheme === "wallet" ? theme.other.paletteNew.algaeGreen20 : theme.other.paletteNew.darkModeDarkBlue50 ) : ( props?.colorTheme === "wallet" ? theme.other.paletteNew.algaeGreen10 : theme.other.paletteNew.primaryDarkBlue10 ),
				outline    : props?.colorTheme === "wallet" ? `1px solid ${ theme.other.paletteNew.algaeGreen }` : `1px solid ${ theme.other.paletteNew.primaryDarkBlue }`
			},

			":focus": {
				outline: props?.colorTheme === "wallet" ? important ( `1px solid ${ theme.other.paletteNew.algaeGreen }` ) : important ( `1px solid ${ theme.other.paletteNew.primaryDarkBlue }` )
			},

			"&:last-child": {
				marginRight: "0"
			},
			[rooterMediaQuery.desktop]: {
				flex: 1
			}

		},
		backgroundWrapper: {
			backgroundColor: "inherit"
		},
		tabList: {
			padding                : "1.6rem 1.4rem",
			overflow               : "auto",
			whiteSpace             : "nowrap",
			msOverFlowStytle       : "none", /* Internet Explorer 10+ */
			scrollBarWidth         : "none", /* Firefox */
			"&::-webkit-scrollbar" : {
				display: "none" /* Safari and Chrome */
			},
			[rooterMediaQuery.desktop]: {
				display: "flex"
			}
		},

		tabPanel: {
			height       : "85%",
			marginTop    : "1rem",
			borderRadius : "1.5rem",
			padding      : "2rem 0"
		}
	};
} );
