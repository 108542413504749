import { useRouter } from "next/router";
import { Fragment }  from "react";

import { getShopLink }      from "_common/utils/shop";
import FetchMore            from "_components/atoms/fetchMoreLoader";
import Render               from "_components/atoms/render";
import MobileTabs           from "_components/molecules/mobile/tabs";
import VideoCardList        from "_components/organisms/videoCardList/container";
import useStyles            from "_components/organisms/videoCardList/styles";
import VideoCard            from "_components/organisms/videoCardList/videoCard";
import GameSection          from "_components/templates/homepage/gameSection";
import Header               from "_components/templates/homepage/header";
import ShopProductsSections from "_components/templates/homepage/shopProductsSections";

import Ads from "./ads";

const component = ( {
	tabsList,
	activeTabId,
	onChangeTab,
	feedList,
	isLoading,
	loading,
	isDepleted,
	fetchMore,
	gameList,
	reelList,
	shopData
} ) => {

	const { classes } = useStyles ( { activeTabId } );
	const router      = useRouter ();

	const onClickViewMore = path => {
		switch ( path ) {
			case "reels":
				router.push ( "/reels" );

				break;

			case "shop":
				{
					const url = getShopLink ( { source: "home_page", medium: "web", campaign: "view_more" } );

					window.open ( url, "_blank" );
				}

				break;

			default:
				break;
		}
	};

	const defaultTab = activeTabId || "-1";

	return (
		<div className = { classes.mobileFeedContainer }>
			<div className = "bottomSpacing">
				<MobileTabs
					background     = "secondary"
					defaultTab     = { defaultTab }
					onClickHandler = { onChangeTab }
					tabs           = { tabsList }
				/>
			</div>

			{
				feedList.map ( ( item, idx ) => {
					return (
						<Fragment key = { item.id }>
							<Ads idx = { idx } />

							<VideoCard
								key                = { item.id }
								activeTab          = { activeTabId }
								data               = { item }
								isMobile           = { true }
								isWithoutAnimation = { true }
							/>

							<Render condition = { idx === 1 && defaultTab }>

								<div className = { classes.reelContainer }>

									<Header
										onClickViewMore = { () => onClickViewMore ( "shop" ) }
										title           = { "Rooter Shop" }
									/>

									<ShopProductsSections loading = { loading }
										shopData                     = { shopData }
									/>
								</div>
							</Render>

							<Render condition = { idx === 2 && defaultTab }>
								<GameSection
									gameList = { gameList }
									title    = { "Recommended Categories" }
								/>
							</Render>

							<Render condition = { idx === 3 && defaultTab }>
								<div className = { classes.reelContainer }>
									<div className = { classes.spacer }>
										<Header
											onClickViewMore = { () => onClickViewMore ( "reels" ) }
											title           = { "Reels" }
										/>
									</div>

									<div className = { classes.spacer }>
										<VideoCardList
											cardType = { "reel" }
											from     = { "homeReel" }
											list     = { reelList.slice ( 0, 2 ) }
										/>
									</div>

								</div>

								<div className = { classes.divider } />
							</Render>

						</Fragment>
					);
				} )
			}

			<FetchMore
				fetchMore  = { fetchMore }
				isDepleted = { isDepleted }
				isLoading  = { isLoading }
			/>
		</div>
	);
};

export default component;
