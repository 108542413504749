import React from "react";

import Presentation from "./presentation";

const MobileTabs = ( {
	tabs,
	colorTheme,
	defaultTab,
	onClickHandler,
	background = "primary"
} ) => {

	return (
		<Presentation
			background          = { background }
			colorTheme          = { colorTheme }
			defaultTab          = { defaultTab }
			onClickHandler      = { onClickHandler }
			tabs                = { tabs?.map ( item => ( { value: item.value.toString (), label: item.label } ) ) }
		/>
	);
};

export default MobileTabs;
