import { Tabs as MantineTabs } from "@mantine/core";

import Typography from "_components/atoms/typography";

import useStyles from "./styles";

const MobileTab = ( { value = "", label = "" } ) => {
	const { classes } = useStyles ( undefined, { name: "styles" } );

	return (
		<MantineTabs.Tab
			value = { value }
		>
			<Typography
				className = { classes.tabText }
				title     = { label }
			/>
		</MantineTabs.Tab>
	);
};

export default MobileTab;