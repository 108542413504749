import { createStyles } from "@mantine/emotion";

export default createStyles ( () => {
	return {
		tabText: {
			color         : "inherit",
			whiteSpace    : "nowrap",
			fontSize      : "1.4rem",
			fontWeight    : "500",
			textTransform : "capitalize !important",
			letterSpacing : "normal !important"
		}
	};
} );
