import { useRouter }  from "next/router";
import useSWRInfinite from "swr/infinite";

import { useAddQueryParam }           from "_common/hooks/useQueryParams";
import { formatPersonalizedFeedData } from "_common/utils/broadcast";
import { getPersonalizedFeedDataV2 }  from "_services/api/feed";

import Presentation from "./presentation";

const swrKey = "home-page-feeds-by-feedId";

const fetcher = async params => {
	const { pageNo, feedId } = params;

	const feedDataParams = {
		pageNo,
		pageSize   : 10,
		feedTypeId : feedId || -1
	};

	let result = await getPersonalizedFeedDataV2 ( feedDataParams );

	return formatPersonalizedFeedData ( result ) || [];
};

const HomepageMobile = ( { allTabsWithFeedList, gameList, reelList, loading, shopData } ) => {
	const router        = useRouter ();
	const addQueryParam = useAddQueryParam ();
	const activeTabId   = router.query.tab;

	const { data, error, size, setSize } = useSWRInfinite (
		index => {
			return {
				key    : swrKey,
				pageNo : index + 1,
				feedId : activeTabId

			};
		},
		fetcher,
		{ revalidateFirstPage: false }
	);

	const isLoadingInitialData = !data && !error;
	const isEmpty              = data?.[size - 1]?.length === 0;
	const isLoadingMore        = isLoadingInitialData || ( size > 0 && data && typeof data[size - 1] === "undefined" );
	const feedList             = [].concat.apply ( [], data );

	const fetchMore = () => {
		setSize ( size + 1 );
	};

	const onChangeTab = tabId => {
		addQueryParam ( {
			tab: tabId
		} );
	};

	const tabsList = allTabsWithFeedList.map ( item => ( { label: item.display_name, value: item.feedTypeId } ) );

	return (
		<Presentation
			activeTabId         = { activeTabId }
			allTabsWithFeedList = { allTabsWithFeedList }
			feedList            = { feedList }
			fetchMore           = { fetchMore }
			gameList            = { gameList }
			isDepleted          = { isEmpty }
			isLoading           = { isLoadingMore }
			loading             = { loading }
			onChangeTab         = { onChangeTab }
			reelList            = { reelList }
			shopData            = { shopData }
			tabsList            = { tabsList }
		/>
	);
};

export default HomepageMobile;
